import React, { useState } from "react";
import MApp from "./MApp";
import spudcraftLogo from "../img/Spudcraft.png"
import FooterLinks from "./FooterLinks";
import jumpingSpud from "../img/FunkNJump.gif";
import GameBoard from "./GameBoard"; // Import the GameBoard component
import bandventureLogo from "../img/BandventureLogo.png"; // Add logo for the new game

function Games() {
  const [spudcraft, setSpudcraft] = useState(true);
  const [bandventure, setBandventure] = useState(true);

  function onSpudcraftClick() {
    setSpudcraft(false);
  }

  function onSpudcraftClose() {
    setSpudcraft(true);
  }

  function onBandventureClick() {
    setBandventure(false);
  }

  function onBandventureClose() {
    setBandventure(true);
  }

  return (
    <div className="container text-center">
    <h3 className="mt-5 mb-5 rocksalt">Click to play</h3>
{/*        Spudcraft Section }
      <div>
        {spudcraft ? (
          <div>
            <div>
              <img
                className="spuddies"
                style={{ width: "50%", height: "auto" }}
                src={spudcraftLogo}
                alt="Spudcraft logo"
                onClick={onSpudcraftClick}
              />
            </div>
          </div>
        ) : (
          <div>
            <div style={{ display: "inline-block" }}>
            <h1 className="m-2 spudsite rocksalt">Spudcraft</h1>
              <div
                className="m-2 text-center"
                style={{
                  border: "10px solid black",
                  borderRadius: "5px",
                  height: "550px",
                  width: "700px",
                }}
              >
                <MApp />
              </div>
            </div>
            <button
              className="btn btn-danger rocksalt"
              onClick={onSpudcraftClose}
            >
              X
            </button>
          </div>
        )}
      </div>*/ }

      {/* Bandventure Quest Section */}
      <div>
        {bandventure ? (
          <div className="">
            <div>
              <img
                className="spuddies"
                style={{ width: "50%", height: "auto" }}
                src={bandventureLogo}
                alt="Bandventure Quest logo"
                onClick={onBandventureClick}
              />
            </div>
          </div>
        ) : (
          <div>
            <div style={{ display: "inline-block" }}>
            <h1 className="m-4 spudsite rocksalt">Get To The Venue</h1>
              <div
                className="m-2 text-center"
                style={{
                    border: "10px solid black",
                    borderRadius: "5px",
                    maxWidth: "800px", // Set a maximum width
                    margin: "0 auto", // Center the container
                    overflow: "auto",
                  }}
              >
                <GameBoard />
              </div>
            </div>
            <button
              className="btn btn-danger rocksalt"
              onClick={onBandventureClose}
            >
              X
            </button>
            {/* Controls or additional content for Bandventure Quest */}
          </div>
        )}
      </div>
            <div className="container text-center mt-3">
                <FooterLinks />
                <div className="text-center">
                    <a href="https://lens.snapchat.com/9ab8073ff16547e5bd413f811fb3e513?sender_web_id=4a206bd0-61c0-4d01-9a1c-0db2f2654c50&device_type=desktop&is_copy_url=true">
                        <img style={{ height: "10em", width: "15em" }} src={jumpingSpud} alt="Jumping Spud" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Games;